// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "~@angular/material" as mat;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$funnelbuilder-primary: mat.define-palette(mat.$indigo-palette);
$funnelbuilder-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$funnelbuilder-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$funnelbuilder-theme: mat.define-light-theme(
  (
    color: (
      primary: $funnelbuilder-primary,
      accent: $funnelbuilder-accent,
      warn: $funnelbuilder-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($funnelbuilder-theme);

/* You can add global styles to this file, and also import other style files */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}
.StripeElement--invalid {
  border-color: #fa755a;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
.stripe {
  width: 50%;
}

html,
body {
  min-height: 100vh;
}
body {
  margin: 0;
  font-family: "Inter", Roboto, "Helvetica Neue", sans-serif;
}
.grecaptcha-badge {
  visibility: hidden;
}

[contenteditable]:focus {
  outline: 0px solid transparent;
}

input {
  opacity: 1;
  --placeHolder-color: #6f6f6f;
}

input::placeholder {
  color: var(--placeHolder-color);
}

input::-webkit-input-placeholder {
  /* Edge */
  color: var(--placeHolder-color);
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--placeHolder-color);
}

input:-moz-placeholder {
  color: var(--placeHolder-color);
}

input::-moz-placeholder {
  color: var(--placeHolder-color);
}
